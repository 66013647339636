<template lang="">
    <Fieldset legend="Horários">
        <div v-if="!filtro.agendaId" class="h-full flex align-content-center justify-content-center align-items-center">
            <div class="flex agenda-aviso">Informe uma agenda para pesquisar</div>
            <i class="icon-aviso pi pi-search"></i>
        </div>
        <div v-if="filtro.agendaId && !records.length" class="h-full flex align-content-center justify-content-center align-items-center">
            <div class="flex agenda-aviso">Nenhum registro encontrado para os critérios de busca</div>
            <i class="icon-aviso pi pi-search"></i>
        </div>

        <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />

        <DataTable v-if="filtro.agendaId" :value="records" responsiveLayout="scroll">
            <Column field="dataHoraInicial" header="Horário">
                <template #body="{ data }">
                    {{ $filters.formatTime(data.dataHoraInicial) }} até
                    {{ $filters.formatTime(data.dataHoraFinal) }}
                </template>
            </Column>
            <Column field="procedimentos" header="Procedimentos"></Column>
            <Column field="tipoExameNome" header="Tipo de exame"></Column>
            <Column field="funcionarioNome" header="Funcionário">
                <template #body="{ data }">
                    <Button v-if="data.agendamentoId" :label="data.funcionarioNome" class="p-button-link" @click="onClickRastrearAgendamento(data)" />
                </template>
            </Column>
            <Column field="status" header="Status"></Column>
            <Column field="dataHoraInicial" header="Cancelar">
                <template #body="{ data }">
                    <Button
                        v-if="data.id && data.status != `CANCELADO`"
                        icon="pi pi-times"
                        class="p-button-danger"
                        @click="onClickCancelar($event, data)"
                    />
                </template>
            </Column>
        </DataTable>

        <ConfirmPopup group="demo">
            <template #message="slotProps">
                <div class="flex p-4">
                    <i :class="slotProps.message.icon" style="font-size: 1.5rem"></i>
                    <p class="pl-2">{{ slotProps.message.message }}</p>
                </div>
            </template>
        </ConfirmPopup>        
    </Fieldset>
</template>
<script>
import { showSuccess, showError } from '@/utils/Toast';
import AgendaReservaHorarioService from '../services/AgendaReservaHorarioService';

export default {
    name: 'HorariosSection',
    components: {},
    props: {
        filtro: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showOutrasReservasDialog: false,
            records: [],
            loading:  false
        };
    },
    computed: {
        dataSelecionada() {
            return this.$route.params.data;
        },
        filtroLocal: {
            get: function () {
                return this.filtro || {};
            }
        }
    },
    created() {
        this.$service = new AgendaReservaHorarioService();
    },
    methods: {
        async load() {
            try {
                const { agendaId, funcionarioId, clienteId } = this.filtro;
                if (!agendaId) {
                    return;
                }
                this.loading = true;
                const { data } = await this.$service.getEventosDia({
                    agendaId,
                    funcionarioId,
                    clienteId,
                    data: this.dataSelecionada
                });
                this.records = data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
                showError(this.$toast, error);
            }
        },
        onClickConsultarOutrasReservas() {
            this.showOutrasReservasDialog = true;
        },
        onClickRastrearAgendamento(record) {
            this.$router.push('/painel-agendamento/list?id=' + record.agendamentoId);
        },
        onClickCancelar(event, record) {
            this.$confirm.require({
                target: event.currentTarget,
                group: 'demo',
                message: 'Deseja realmente cancelar?',
                icon: 'pi pi-question-circle',
                acceptIcon: 'pi pi-check',
                rejectIcon: 'pi pi-times',
                accept: () => {
                    this.cancelar(record);
                }
            });
        },
        async cancelar(record) {
            try {
                const response = await this.$service.cancelar(record.id);
                record.status = response.data.status;
                showSuccess(this.$toast, 'Cancelado com sucesso');
            } catch (error) {
                showError(this.$toast, error);
            }
        }
    }
};
</script>
<style scoped lang="scss">
.agenda-aviso {
    font-size: 20px;
    height: auto;
    color: #b6bfc7;
}

.icon-aviso {
    font-size: 10rem;
    color: #ced4da;
    margin-left: 15px;
}
</style>