<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <div class="p-fluid grid">
                    <div class="col-6">
                        <Fieldset legend="Pesquisa">
                            <div class="grid">
                                <div class="field col-6">
                                    <label for="agenda">Agenda</label>
                                    <DropdownAgenda id="agenda" v-model="filtro.agenda" :idValue="filtro.agendaId"></DropdownAgenda>
                                </div>
                                <div class="field col-6">
                                    <label for="funcionario">Funcionário</label>
                                    <DropdownFuncionario id="funcionario" v-model="filtro.funcionario" :idValue="filtro.funcionarioId" />
                                </div>
                                <div class="field col-6">
                                    <label for="cliente">Cliente</label>
                                    <DropdownCliente id="cliente" v-model="filtro.cliente" :idValue="filtro.clienteId" />
                                </div>
                                <div v-if="filtro.funcionarioId" class="field col-6">
                                    <Button
                                        label="Exames do funcionário"
                                        class="p-button-link link-exames-funcionario"
                                        @click="onClickExamesFuncionario"
                                    />
                                </div>
                            </div>
                        </Fieldset>
                    </div>
                    <div class="col-6">
                        <FuncionarioSection v-if="filtro.funcionarioId && filtro.funcionario.customerId" :filtro="filtro"/>
                    </div>
                    <div class="col-12">
                        <HorariosSection ref="horarios" :filtro="filtro" />
                    </div>
                </div>
            </div>
        </div>

        <ExamesFuncionarioDialog
            v-if="showExamesFuncionario"
            v-model:visible="showExamesFuncionario"
            :funcionario="filtro.funcionario"
            :dataSelecionada="dataSelecionada"
        />
    </div>
</template>

<script>
import AgendaReservaHorarioService from './services/AgendaReservaHorarioService';
import DropdownAgenda from '../agenda/components/DropdownAgenda';
import DropdownFuncionario from '../funcionarios/components/DropdownFuncionario.vue';
import DropdownCliente from '../clientes/components/DropdownCliente.vue';
import ExamesFuncionarioDialog from './components/ExamesFuncionarioDialog.vue';
import FuncionarioSection from './components/FuncionarioSection.vue'
import HorariosSection from './components/HorariosSection.vue'

export default {
    components: {
        DropdownAgenda,
        DropdownFuncionario,
        DropdownCliente,
        ExamesFuncionarioDialog,
        FuncionarioSection,
        HorariosSection
    },
    data() {
        return {
            filtro: {},
            records: [],
            showOutrasReservasDialog: false,
            showExamesFuncionario: false
        };
    },
    created() {
        this.$service = new AgendaReservaHorarioService();
    },
    mounted() {
        this.load();
    },
    computed: {
        dataSelecionada() {
            return this.$route.params.data;
        }
    },
    watch: {
        'filtro.agenda'(newValue) {
            this.filtro.agendaId = newValue?.id;
            this.load();
        },
        'filtro.funcionario'(newValue) {
            this.filtro.funcionarioId = newValue?.id;
            if (this.filtro.funcionario) {
                this.filtro = {
                    ...this.filtro,
                    unidadeId: this.filtro.funcionario.branch.id,
                    setorId: this.filtro.funcionario.department.id,
                    cargoId: this.filtro.funcionario.position.id,
                    unidade: this.filtro.funcionario.branch,
                    setor: this.filtro.funcionario.department,
                    cargo: this.filtro.funcionario.position
                };
            }
            this.load();
        },
        'filtro.cliente'(newValue) {
            this.filtro.clienteId = newValue?.id;
            this.load();
        },
        'filtro.unidade'(newValue) {
            this.filtro.unidadeId = newValue?.id;
        },
        'filtro.setor'(newValue) {
            this.filtro.setorId = newValue?.id;
        },
        'filtro.cargo'(newValue) {
            this.filtro.cargoId = newValue?.id;
        }
    },
    methods: {
        async load() {
            this.$refs.horarios.load();
        },
        onClickExamesFuncionario() {
            this.showExamesFuncionario = true;
        }
    }
};
</script>

<style scoped lang="scss">
.link-exames-funcionario {
    margin-top: 23px;
}
</style>
