import BaseService from "../../../services/BaseService";
import { getClientBase } from '@/services/http'

export default class AgendaReservaHorarioService extends BaseService {
    constructor() {
        super('agenda-reserva-horario');
        this.path = 'agenda-reserva-horario';
    }

    getEventosCalendario(params) {
        return getClientBase().get(this.path + '/eventos-calendario', { params });
    }

    getEventosDia(params) {
        return getClientBase().get(this.path + '/eventos-dia', { params });
    }

    getEventosFuncionario(params) {
        return getClientBase().get(this.path + '/eventos-funcionario', { params });
    }

    cancelar(id) {
        return getClientBase().post(this.path + '/cancelar/' + id);
    }

}