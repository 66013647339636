<template lang="">
    <Fieldset legend="Funcionário">
        <div class="grid" v-if="filtroLocal.funcionario">
            <div class="field col-6">
                <label for="unidade">Unidade</label>
                <InfinityDropdownUnidadesByCliente
                    id="unidade"
                    :disabled="true"
                    v-model="filtroLocal.unidade"
                    :filtrosExtras="{ customerId: filtroLocal.funcionario.customerId }"
                    showClear
                />
            </div>
            <div class="field col-6">
                <label for="setor">Setor</label>
                <DropdownSetorByHierarquia
                    id="setor"
                    :disabled="true"
                    :autoLoad="false"
                    :loadDataById="false"
                    :customerId="filtroLocal.funcionario.customerId"
                    :branchId="filtroLocal.unidadeId"
                    v-model="filtroLocal.setor"
                    :idValue="filtroLocal.setorId"
                />
            </div>
            <div class="field col-6">
                <label for="cargo">Cargo</label>
                <DropdownCargoByHierarquia
                    id="cargo"
                    :disabled="true"
                    :autoLoad="false"
                    :loadDataById="false"
                    :customerId="filtroLocal.funcionario.customerId"
                    :branchId="filtroLocal.unidadeId"
                    :departmentId="filtroLocal.setorId"
                    v-model="filtroLocal.cargo"
                />
            </div>
            <div class="field col-6">
                <Button label="Consultar outras reservas" class="p-button-link link-outras-reservas" @click="onClickConsultarOutrasReservas" />
            </div>
        </div>
        <OutrasReservasDialog
            v-if="showOutrasReservasDialog"
            v-model:visible="showOutrasReservasDialog"
            :funcionario="filtroLocal.funcionario"
            :dataSelecionada="dataSelecionada"
        />
    </Fieldset>
</template>
<script>
import DropdownSetorByHierarquia from '../../funcionarios/components/DropDownsPorHierarquia/DropdownSetorByHierarquia.vue';
import DropdownCargoByHierarquia from '../../funcionarios/components/DropDownsPorHierarquia/DropdownCargoByHierarquia.vue';
import OutrasReservasDialog from './OutrasReservasDialog.vue';
import InfinityDropdownUnidadesByCliente from '../../unidades/components/InfinityDropdownUnidadesByCliente.vue';

export default {
    name: 'FuncionarioSection',
    components: {
        InfinityDropdownUnidadesByCliente,
        DropdownSetorByHierarquia,
        DropdownCargoByHierarquia,
        OutrasReservasDialog
    },
    props: {
        filtro: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showOutrasReservasDialog: false
        };
    },
    computed: {
        dataSelecionada() {
            return this.$route.params.data;
        },
        filtroLocal: {
            get: function () {
                return this.filtro || {}
            }
        }
    },
    methods: {
        onClickConsultarOutrasReservas() {
            this.showOutrasReservasDialog = true;
        }
    }
};
</script>
<style scoped lang="scss">
.link-outras-reservas {
    margin-top: 23px;
}
</style>

