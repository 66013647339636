import BaseService from "../../../services/BaseService";
import { getClientBase } from '@/services/http'

export default class FuncionarioService extends BaseService {
    constructor() {
        super('customer/employees');
        this.path = 'customer/employees';
    }

    buscarExamesFuncionarioSoc(id) {
        return getClientBase().get(this.path + '/exames-funcionario-soc/' + id);
    }
}