<template>
    <Dialog
        ref="dialog"
        :breakpoints="{ '1000px': '60vw', '650px': '80vw' }"
        :style="{ width: '60vw' }"
        class="p-fluid"
        :modal="true"
        header="Horários para o funcionário"
    >
        <div>
            <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
            <div v-if="!records.length" class="h-full flex align-content-center justify-content-center align-items-center">
                <div class="flex agenda-aviso">Nenhum registro encontrado para os critérios de busca</div>
                <i class="icon-aviso pi pi-search"></i>
            </div>
            <DataTable v-if="records.length" :value="records" responsiveLayout="scroll">
                <Column field="dataHoraInicial" header="Horário">
                    <template #body="{ data }">
                        {{ $filters.formatTime(data.dataHoraInicial) }} até
                        {{ $filters.formatTime(data.dataHoraFinal) }}
                    </template>
                </Column>
                <Column field="agendaNome" header="Agenda"></Column>
                <Column field="procedimentos" header="Procedimentos"></Column>
                <Column field="tipoExameNome" header="Tipo de exame"></Column>
                <Column field="funcionarioNome" header="Funcionário"></Column>
                <Column field="status" header="Status"></Column>
            </DataTable>
        </div>
        <template #footer>
            <div>
                <Button label="Fechar" icon="pi pi-times" class="p-button-outlined p-button-danger" @click="onClickClose" />
            </div>
        </template>
    </Dialog>
</template>
<script>
import AgendaReservaHorarioService from '../services/AgendaReservaHorarioService';

export default {
    name: 'OutrasReservasDialog',
    props: {
        funcionario: {
            type: Object,
            required: true
        },
        dataSelecionada: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            records: [],
            loading: true
        };
    },
    created() {
        this.$service = new AgendaReservaHorarioService();
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            try {
                this.loading = true;
                const { data } = await this.$service.getEventosFuncionario({
                    funcionarioId: this.funcionario.id,
                    data: this.dataSelecionada
                });
                this.records = data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        onClickClose() {
            this.$refs.dialog.close();
        }
    }
};
</script>
<style scoped lang="scss">
.agenda-aviso {
    font-size: 20px;
    height: auto;
    color: #b6bfc7;
}

.icon-aviso {
    font-size: 10rem;
    color: #ced4da;
    margin-left: 15px;
}
</style>
