<template>
    <Dialog
        ref="dialog"
        :breakpoints="{ '1000px': '60vw', '650px': '80vw' }"
        :style="{ width: '60vw' }"
        class="p-fluid"
        :modal="true"
        header="Exames para o funcionário"
    >
        <div>
            <ProgressBar v-if="loading" mode="indeterminate" style="height: 0.3em" />
            <div v-if="!records.length" class="h-full flex align-content-center justify-content-center align-items-center">
                <div class="flex agenda-aviso">Nenhum registro encontrado para os critérios de busca</div>
                <i class="icon-aviso pi pi-search"></i>
            </div>
            <DataTable v-if="records.length" :value="records" responsiveLayout="scroll">
                <Column field="codigoExame" header="Código"></Column>
                <Column field="nomeExame" header="Exame"></Column>
                <Column field="periodicidade" header="Periodicidade"></Column>
                <Column field="nomeEmpresa" header="Empresa"></Column>
            </DataTable>
        </div>
        <template #footer>
            <div>
                <Button label="Fechar" icon="pi pi-times" class="p-button-outlined p-button-danger" @click="onClickClose" />
            </div>
        </template>
    </Dialog>
</template>
<script>
import FuncionarioService from '../../funcionarios/services/FuncionarioService';

export default {
    name: 'ExamesFuncionarioDialog',
    props: {
        funcionario: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            records: [],
            loading: true
        };
    },
    created() {
        this.$service = new FuncionarioService();
    },
    mounted() {
        this.load();
    },
    methods: {
        async load() {
            try {
                this.loading = true;
                const { data } = await this.$service.buscarExamesFuncionarioSoc(this.funcionario.id);
                this.records = data;
                this.loading = false;
            } catch (error) {
                this.loading = false;
            }
        },
        onClickClose() {
            this.$refs.dialog.close();
        }
    }
};
</script>
<style scoped lang="scss">
.agenda-aviso {
    font-size: 20px;
    height: auto;
    color: #b6bfc7;
}

.icon-aviso {
    font-size: 10rem;
    color: #ced4da;
    margin-left: 15px;
}
</style>
